<template>
  <b-container class="px-5">
    <b-card>
      <b-form @submit.prevent="sendData">
        <b-row>
          <b-col class="text-center text-md-left" md="6">
            <feather-icon
              icon="SearchIcon"
              size="100"
              class="mb-1"
            ></feather-icon>
            <h3>Cerca Cliente</h3>
            <b-card-text
              >Utilizza uno dei campi per cercare un utente e accedi alla Scheda
              Cliente</b-card-text
            >
          </b-col>
          <b-col class="mt-3 mt-md-0" md="6">
            <!-- first name -->
            <b-col md="12">
              <b-form-group label="Nome" label-for="customer_name">
                <b-form-input v-model="customer_name" id="customer_name" />
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col md="12">
              <b-form-group label="Cognome" label-for="customer_surname">
                <b-form-input
                  v-model="customer_surname"
                  id="customer_surname"
                />
              </b-form-group>
            </b-col>

            <!-- mobile -->
            <b-col md="12">
              <b-form-group label="Telefono" label-for="customer_phone">
                <b-form-input
                  v-model="customer_phone"
                  id="customer_phone"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <!-- password -->
            <b-col md="12">
              <b-form-group label="Cod. Fiscale" label-for="customer_taxcode">
                <b-form-input
                  v-model="customer_taxcode"
                  id="customer_taxcode"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                block
              >
                <feather-icon icon="SearchIcon" class="mr-1" />Cerca
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BCardText,
    BCardTitle,
  },

  data: () => ({
    customer_name: "",
    customer_surname: "",
    customer_phone: "",
    customer_taxcode: "",
  }),

  methods: {
    sendData() {
      if (
        this.customer_name == "" &&
        this.customer_surname == "" &&
        this.customer_taxcode == "" &&
        this.customer_phone == ""
      ) {
        this.$swal.fire({
          icon: "warning",
          title: "Attenzione",
          text: "Compila almeno uno dei campi",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$router.push({
          path: "/customer_search/results",
          query: {
            customer_name: this.customer_name,
            customer_surname: this.customer_surname,
            customer_taxcode: this.customer_taxcode,
            customer_phone: this.customer_phone,
          },
        });
      }
    },
  },

  directives: {
    Ripple,
  },
};
</script>
